<template>
  <div class="need-tg">
    <div class="need-tg__main">
      <div class="need-tg__title">
        <span>
          Привязка аккаунта {{ user.email }} к Telegram-боту
          <Icon type="telegram" />
        </span>
      </div>
      <div class="need-tg__desc">
        <span>
          Для будущих авторизаций в личном кабинете Лиги студентов необходимо
          обязательно привязать свой аккаунт к Telegram-боту, что позволит Вам:
        </span>
        <ul>
          <li>комфортно заходить в личный кабинет;</li>
          <li>получать уведомления о начале конкурсов;</li>
          <li>
            получать информацию о вашем статусе, в зарегистрированных Вами
            конкурсах;
          </li>
          <li>и многое другое.</li>
        </ul>
      </div>
      <div class="need-tg__btns">
        <a :href="isTelegram" target="_blank" @click="addTg">
          <Button :disabled="!isTelegram ?? tgDisabled">
            Перейти в Telegram
          </Button>
        </a>
        <div class="need-tg__btns--success" v-if="!isTelegram || tgDisabled">
          <p>После успешной привязки аккаунта, нажмите на кнопку ниже:</p>
          <Button @click="goToLk" green> Перейти в личный кабинет </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Icon from "@/components/Blocks/Icon";
import Button from "@/components/Blocks/Button";
import needTgEvents from "@/enums/needTgEvents";
export default {
  name: "NeedTelegram",
  components: { Button, Icon },
  computed: {
    ...mapState("user", ["isTelegram", "user"]),
  },
  data() {
    return {
      tgDisabled: false,
      needTgEvents,
    };
  },
  methods: {
    async addTg() {
      this.tgDisabled = true;
    },
    goToLk() {
      if (!this.isTelegram || this.tgDisabled) {
        this.$store.dispatch("user/getUserInfo").then(() => {
          if (this.isTelegram) {
            location.reload();
          } else this.$router.push({ name: "Events" });
        });
      }
    },
  },
};
</script>

<style lang="scss">
.need-tg {
  color: var(--text--color);
  font-weight: 400;
  line-height: 2.2rem;
  font-size: 1.6rem;
  &__main {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    font-size: 3rem;
    line-height: 3rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    margin-bottom: 20px;
    text-align: center;
    i {
      vertical-align: middle;
      font-size: 3rem;
      color: #00b2ff;
    }
  }
  &__desc {
    max-width: 600px;
    ul {
      padding-left: 25px;
    }
  }
  &__btns {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    &--success {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 32px;
      p {
        margin-bottom: 15px;
      }
    }
  }
  &__events {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    &-list {
      display: flex;
      grid-gap: 40px;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
    }
    &-item {
      padding: 20px;
      border-radius: 5px;
      max-width: 380px;
      border: 1px solid #e6e6e6;
      width: 100%;
    }
    &-img {
      width: 100%;
      border-radius: 5px;
      height: 215px;
      overflow: hidden;
      margin-bottom: 24px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-title {
      font-weight: 500;
      font-size: 2.2rem;
      line-height: 2.7rem;
    }
  }
}
</style>
