export default [
  {
    img: require("@/assets/TG.jpg"),
    title: "Транспортный грант",
  },
  {
    img: require("@/assets/SG.svg"),
    title: "Студент года",
  },
  {
    img: require("@/assets/DG.jpg"),
    title: "Достижение года",
  },
];
